<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <c-table
        ref="gridArea"
        title="공사구역 목록"
        tableId="gridArea"
        :columns="gridArea.columns"
        :data="gridArea.data"
        selection="multiple"
        rowKey="pjmProjectAreaId"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isInUserAll="true"
        :isExcelDown="false"
        @rowClick="rowClickDetail"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && param.plantCd" label="추가" icon="add" @btnClicked="addrowArea" />
            <c-btn v-if="editable && param.plantCd" label="저장" icon="save" @btnClicked="saveArea" />
            <c-btn v-if="editable && param.plantCd" label="삭제" icon="remove" @btnClicked="deleteArea" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{  col }">
          <template>
            <q-chip
              v-if="col.name==='openDetail'"
              color="green"
              :clickable="true"
              text-color="white">
              ▶
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
      <c-card title="공사구역별 사용공종 및 안전보건체제" class="cardClassDetailForm" topClass="topcolor-orange">
        <template slot="card-detail">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:-10px;">
              <c-table
                ref="gridProcess"
                title="공종"
                tableId="gridProcess"
                topBorderClass="topcolor-orange"
                :columns="gridProcess.columns"
                :data="gridProcess.data"
                selection="multiple"
                rowKey="processCd"
                :isFullScreen="false"
                :columnSetting="false"
                :filtering="false"
                :usePaging="false"
                :isExcelDown="false"
                gridHeight="340px"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn v-if="editable && param.plantCd && selectedArea.pjmProjectAreaId" label="추가" icon="add" @btnClicked="addrowProcess" />
                    <c-btn v-if="editable && param.plantCd && selectedArea.pjmProjectAreaId" label="저장" icon="save" @btnClicked="saveProcess" />
                    <c-btn v-if="editable && param.plantCd && selectedArea.pjmProjectAreaId" label="삭제" icon="remove" @btnClicked="deleteProcess" />
                  </q-btn-group>
                </template>
              </c-table>
              <c-table
                ref="gridOrg"
                title="안전보건체제"
                tableId="gridOrg"
                topBorderClass="topcolor-orange"
                :columns="gridOrg.columns"
                :data="gridOrg.data"
                selection="multiple"
                rowKey="pjmProjectOrgId"
                :isFullScreen="false"
                :columnSetting="false"
                :filtering="false"
                :usePaging="false"
                gridHeight="340px"
                :isExcelDown="false"
                :isInUserAll="true"
              >
                <!-- 버튼 영역 -->
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn v-if="editable && param.plantCd && selectedArea.pjmProjectAreaId" label="추가" icon="add" @btnClicked="addrowOrg" />
                    <c-btn v-if="editable && param.plantCd && selectedArea.pjmProjectAreaId" label="저장" icon="save" @btnClicked="saveOrg" />
                    <c-btn v-if="editable && param.plantCd && selectedArea.pjmProjectAreaId" label="삭제" icon="remove" @btnClicked="deleteOrg" />
                  </q-btn-group>
                </template>
              </c-table>
            </div>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-bom',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      selectedArea: {},
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      gridOrg: {
        columns: [],
        data: [],
      },
      gridArea: {
        columns: [
          {
            name: 'pjmProjectAreaName',
            field: 'pjmProjectAreaName',
            label: '공사구역명',
            align: 'left',
            style: "width:250px",
            type: 'text',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            type: 'text',
            align: 'left',
            sortable: true,
          },
          {
            name: 'openDetail',
            field: 'openDetail',
            label: '선택',
            align: 'center',
            style: 'width:70px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      gridProcess: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공종명',
            required: true,
            align: 'left',
            style: "width:300px",
            sortable: false,
          },
          {
            name: 'planStartDt',
            field: 'planStartDt',
            label: '시작예정일',
            align: 'center',
            style: "width:150px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'planEndDt',
            field: 'planEndDt',
            label: '종료예정일',
            align: 'center',
            style: "width:150px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'realStartDt',
            field: 'realStartDt',
            label: '실제시작일',
            align: 'center',
            style: "width:150px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'realEndDt',
            field: 'realEndDt',
            label: '실제종료일',
            align: 'center',
            style: "width:150px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'resultPercent',
            field: 'resultPercent',
            label: '실적진행율(%)',
            align: 'right',
            style: "width:100px",
            type: 'number',
            sortable: false,
          },
          // {
          //   name: 'processDesc',
          //   field: 'processDesc',
          //   label: '비고',
          //   align: 'left',
          //   type: 'text',
          //   sortable: false,
          // },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            required: true,
            align: 'center',
            type: 'number',
            style: "width:80px",
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listOrgUrl: '',
      saveOrgUrl: '',
      deleteOrgUrl: '',
      listAreaUrl: '',
      saveAreaUrl: '',
      deleteAreaUrl: '',
      listProcessUrl: '',
      saveProcessUrl: '',
      deleteProcessUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    param() {
      this.getList()
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.infoUrl = selectConfig.pjm.info.get.url;
      this.listOrgUrl = selectConfig.pjm.org.list.url;
      this.saveOrgUrl = transactionConfig.pjm.org.save.url;
      this.deleteOrgUrl = transactionConfig.pjm.org.delete.url;
      this.listAreaUrl = selectConfig.pjm.area.list.url;
      this.saveAreaUrl = transactionConfig.pjm.area.save.url;
      this.deleteAreaUrl = transactionConfig.pjm.area.delete.url;
      this.listProcessUrl = selectConfig.pjm.area.process.list.url;
      this.saveProcessUrl = transactionConfig.pjm.area.process.save.url;
      this.deleteProcessUrl = transactionConfig.pjm.area.process.delete.url;
      
      this.$comm.getComboItems('SAFETY_ORG_ROLE').then(_result => {
        _result = this.$_.filter(_result, {attrVal2 : 'Y' });
        this.gridOrg.columns = [
          {
            name: 'projectRole',
            field: 'projectRole',
            valueName: 'codeName',
            label: '역할',
            align: 'center',
            style: "width:200px",
            sortable: false,
            type: 'select',
            required: true,
            comboItems: _result,
          },
          {
            name: 'projectRoleUserName',
            field: 'projectRoleUserName',
            label: '담당자',
            align: 'left',
            sortable: false,
            required: true,
            style: 'width:150px',
            type: 'user',
            userId: 'projectRoleUserId',
          },
          {
            name: 'roleStartDt',
            field: 'roleStartDt',
            label: '투입일',
            align: 'center',
            type: 'date',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'roleEndDt',
            field: 'roleEndDt',
            label: '철수일',
            align: 'center',
            type: 'date',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'roleAppointDt',
            field: 'roleAppointDt',
            label: '선임일',
            align: 'center',
            type: 'date',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '첨부파일',
            align: 'center',
            style: 'width:300px',
            type: 'attach',
            taskClassCd: 'SAFETY_ORG_ROLE_ATTR1',
            keyText: 'pjmProjectOrgId',
            sortable: false,
          },
        ]
      });
      this.getAreaList();
    },
    getAreaList() {
      this.selectedArea = {};
      this.gridProcess.data = [];
      this.gridOrg.data = [];
      this.$http.url = this.listAreaUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.param.plantCd
      }
      this.$http.request((_result) => {
        this.gridArea.data = _result.data;
      },);
    },
    rowClickDetail(row) {
      if (row.editFlag != 'C') {
        this.selectedArea = row;
        this.getProcessList(row);
        this.getOrgList(row);
      } else {
        this.selectedArea = {};
        this.gridProcess.data = [];
        this.gridOrg.data = [];
      }
    },
    getProcessList(row) {
      this.$http.url = this.listProcessUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        pjmProjectAreaId: row.pjmProjectAreaId
      }
      this.$http.request((_result) => {
        this.gridProcess.data = _result.data;
      },);
    },
    getOrgList(row) {
      this.$http.url = this.listOrgUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        pjmProjectAreaId: row.pjmProjectAreaId
      }
      this.$http.request((_result) => {
        this.gridOrg.data = _result.data;
      },);
    },
    addrowArea() {
      this.selectedArea = {};
      this.gridProcess.data = [];
      this.gridOrg.data = [];
      this.gridArea.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        pjmProjectAreaId: uid(),  // 공사구역 일련번호
        pjmProjectAreaName: '',  // 공사구역명
        remarks: '',  // 비고
        delFlag: 'N',  // 삭제여부
      })
    },
    saveArea() {
      let checkItem = ['pjmProjectAreaName']
      let isConti = true;
      this.$_.forEach(this.gridArea.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [공사구역명]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.gridArea.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveAreaUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getAreaList();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteArea() {
      let selectData = this.$refs['gridArea'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          item.chgUserId = this.$store.getters.user.userId;
        });
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteAreaUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getOrgList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.gridArea.data = this.$_.reject(this.gridArea.data, item);
              })
              this.$refs['gridArea'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrowProcess() {
      this.popupOptions.title = '공사현장 공종 선택';
      this.popupOptions.param = {
        type: 'multiple',
        processLevelCd: '20',
        plantCd: this.param.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeProcess;
    },
    closeProcess(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, (item) => {
          if (
            this.$_.findIndex(this.gridProcess.data, {
              processCd: item.processCd,
            }) === -1
          ) {
            this.gridProcess.data.push({
              editFlag: 'C',
              plantCd: this.param.plantCd,  // 공사현장코드
              pjmProjectAreaId: this.selectedArea.pjmProjectAreaId,  // 공사구역 일련번호
              processCd: item.processCd,  // 공종코드/공사구분코드
              processName: item.processName,  // 공종코드/공사구분코드
              planStartDt: '',  // 시작예정일자
              planEndDt: '',  // 종료예정일자
              realStartDt: '',  // 실제시작일
              realEndDt: '',  // 실제종료일자
              resultPercent: '',  // 실적진행율
              processDesc: '',  // 비고
              sortOrder: item.sortOrder,  // 정렬순서
              delFlag: 'N',  // 삭제여부
            })
          }
        });
      }
    },
    saveProcess() {
      let checkItem = ['planStartDt', 'planEndDt', 'sortOrder']
      let isConti = true;
      this.$_.forEach(this.gridProcess.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [시작예정일자, 종료예정일자, 순번]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.gridProcess.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveProcessUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getProcessList(this.selectedArea);
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteProcess() {
      let selectData = this.$refs['gridProcess'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          item.chgUserId = this.$store.getters.user.userId;
        });
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteProcessUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getOrgList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.gridProcess.data = this.$_.reject(this.gridProcess.data, item);
              })
              this.$refs['gridProcess'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrowOrg() {
      this.gridOrg.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        pjmProjectOrgId: uid(),  // 안전조직 일련번호
        pjmProjectAreaId: this.selectedArea.pjmProjectAreaId,
        projectRole: null,  // 역할
        projectRoleUserId: '',  // 담당자 ID
        roleStartDt: '',  // 근무시작일
        roleEndDt: '',  // 근무종료일
        roleAppointDt: '',  // 선임일
        eduCompleteDt: '',  // 교육수료일
      })
    },
    saveOrg() {
      let checkItem = ['projectRole', 'projectRoleUserId']
      let isConti = true;
      this.$_.forEach(this.gridOrg.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [역할, 담당자]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.gridOrg.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveOrgUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getOrgList(this.selectedArea);
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteOrg() {
      let selectData = this.$refs['gridOrg'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteOrgUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getOrgList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.gridOrg.data = this.$_.reject(this.gridOrg.data, item);
              })
              this.$refs['gridOrg'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>