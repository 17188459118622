var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c(
            "c-table",
            {
              ref: "gridArea",
              attrs: {
                title: "공사구역 목록",
                tableId: "gridArea",
                columns: _vm.gridArea.columns,
                data: _vm.gridArea.data,
                selection: "multiple",
                rowKey: "pjmProjectAreaId",
                isFullScreen: false,
                columnSetting: false,
                filtering: false,
                usePaging: false,
                isInUserAll: true,
                isExcelDown: false,
              },
              on: { rowClick: _vm.rowClickDetail },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ col }) {
                    return [
                      [
                        col.name === "openDetail"
                          ? _c(
                              "q-chip",
                              {
                                attrs: {
                                  color: "green",
                                  clickable: true,
                                  "text-color": "white",
                                },
                              },
                              [_vm._v(" ▶ ")]
                            )
                          : _vm._e(),
                      ],
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.param.plantCd
                        ? _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                            on: { btnClicked: _vm.addrowArea },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.param.plantCd
                        ? _c("c-btn", {
                            attrs: { label: "저장", icon: "save" },
                            on: { btnClicked: _vm.saveArea },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.param.plantCd
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.deleteArea },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: {
                title: "공사구역별 사용공종 및 안전보건체제",
                topClass: "topcolor-orange",
              },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      staticStyle: { "margin-top": "-10px" },
                    },
                    [
                      _c(
                        "c-table",
                        {
                          ref: "gridProcess",
                          attrs: {
                            title: "공종",
                            tableId: "gridProcess",
                            topBorderClass: "topcolor-orange",
                            columns: _vm.gridProcess.columns,
                            data: _vm.gridProcess.data,
                            selection: "multiple",
                            rowKey: "processCd",
                            isFullScreen: false,
                            columnSetting: false,
                            filtering: false,
                            usePaging: false,
                            isExcelDown: false,
                            gridHeight: "340px",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "table-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _vm.editable &&
                                  _vm.param.plantCd &&
                                  _vm.selectedArea.pjmProjectAreaId
                                    ? _c("c-btn", {
                                        attrs: { label: "추가", icon: "add" },
                                        on: { btnClicked: _vm.addrowProcess },
                                      })
                                    : _vm._e(),
                                  _vm.editable &&
                                  _vm.param.plantCd &&
                                  _vm.selectedArea.pjmProjectAreaId
                                    ? _c("c-btn", {
                                        attrs: { label: "저장", icon: "save" },
                                        on: { btnClicked: _vm.saveProcess },
                                      })
                                    : _vm._e(),
                                  _vm.editable &&
                                  _vm.param.plantCd &&
                                  _vm.selectedArea.pjmProjectAreaId
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "삭제",
                                          icon: "remove",
                                        },
                                        on: { btnClicked: _vm.deleteProcess },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "c-table",
                        {
                          ref: "gridOrg",
                          attrs: {
                            title: "안전보건체제",
                            tableId: "gridOrg",
                            topBorderClass: "topcolor-orange",
                            columns: _vm.gridOrg.columns,
                            data: _vm.gridOrg.data,
                            selection: "multiple",
                            rowKey: "pjmProjectOrgId",
                            isFullScreen: false,
                            columnSetting: false,
                            filtering: false,
                            usePaging: false,
                            gridHeight: "340px",
                            isExcelDown: false,
                            isInUserAll: true,
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "table-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _vm.editable &&
                                  _vm.param.plantCd &&
                                  _vm.selectedArea.pjmProjectAreaId
                                    ? _c("c-btn", {
                                        attrs: { label: "추가", icon: "add" },
                                        on: { btnClicked: _vm.addrowOrg },
                                      })
                                    : _vm._e(),
                                  _vm.editable &&
                                  _vm.param.plantCd &&
                                  _vm.selectedArea.pjmProjectAreaId
                                    ? _c("c-btn", {
                                        attrs: { label: "저장", icon: "save" },
                                        on: { btnClicked: _vm.saveOrg },
                                      })
                                    : _vm._e(),
                                  _vm.editable &&
                                  _vm.param.plantCd &&
                                  _vm.selectedArea.pjmProjectAreaId
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "삭제",
                                          icon: "remove",
                                        },
                                        on: { btnClicked: _vm.deleteOrg },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }